import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import withTokenInterceptor from '../../hoc/withTokenInterceptor/withTokenInterceptor';
import { useBoletoDispatch, useBoletoSelector } from '../../store/boleto/BoletoProvider';
import {
  buscarParametrosGerarBoletoAction, imprimirBoletoAbeWebAction, imprimirBoletoDelphiAction,
} from '../../store/boleto/boleto.saga';

function Boleto() {
  const dispatch = useBoletoDispatch();

  const parametrosDecriptografados = useBoletoSelector(states => states.parametrosDecriptografados);

  const { credentials } = useParams();

  function getQueryParam(parametros, chave) {
    if (parametros.includes(chave)) {
      const indexInicio = parametros.indexOf(`&${chave}`) + chave.length + 2;
      let indexFim = parametros.indexOf('&', indexInicio);
      if (indexFim < 0) indexFim = parametros.length;
      return parametros.substr(indexInicio, indexFim - indexInicio);
    }
    return '';
  }

  const extraiParametrosAbeWeb = useCallback((parametros) => {
    const idAcordo = getQueryParam(parametros, 'A');
    const nroParcela = getQueryParam(parametros, 'N');
    return { idAcordo, nroParcela };
  }, []);

  const extraiParametrosDelphi = useCallback((parametros) => {
    const idProcesso = getQueryParam(parametros, 'P');
    const idBoleto = getQueryParam(parametros, 'B');
    return { idProcesso, idBoleto };
  }, []);

  useEffect(() => {
    dispatch(buscarParametrosGerarBoletoAction(credentials));

    if (parametrosDecriptografados) {
      const parametros = parametrosDecriptografados.substring(1);
      const origem = parametrosDecriptografados.substring(0, 1);

      if (origem === 'W') {
        const { idAcordo, nroParcela } = extraiParametrosAbeWeb(parametros);
        if (idAcordo && nroParcela) {
          dispatch(imprimirBoletoAbeWebAction(idAcordo, nroParcela));
        }
      } else if (origem === 'D') {
        const { idProcesso, idBoleto } = extraiParametrosDelphi(parametros);
        dispatch(imprimirBoletoDelphiAction(idProcesso, idBoleto));
      }
    }
  }, [credentials, dispatch, parametrosDecriptografados, extraiParametrosAbeWeb, extraiParametrosDelphi]);

  return (<></>);
}

export default withTokenInterceptor(Boleto);
