import React, { Component } from 'react';
import { gateway, local } from '../../services/api.service';
import SweetAlertGeneric from '../../components/UI/SweetAllert/SweetAlert';

export default function withTokenInterceptor(WrappedComponent) {
  return class extends Component {
    state = {
      error: null,
    };

    componentWillMount() {
      const buildTokenInterceptor = api => api.interceptors.request.use((req) => {
        if (localStorage.userData) {
          const { accessToken } = JSON.parse(localStorage.userData);
          req.headers.Authorization = accessToken;
        }
        return req;
      });

      const buildErrorResponseInterceptor = api => api.interceptors.response.use(
        res => res,
        (error) => {
          if (error.response === undefined) {
            this.setState({ error });
            return Promise.reject(error);
          }

          if (error.response.status >= 500) {
            this.setState({ error });
          }
          return Promise.reject(error);
        },
      );

      this.reqInterceptor = buildTokenInterceptor(gateway);
      this.reqInterceptorLocal = buildTokenInterceptor(local);

      this.resInterceptor = buildErrorResponseInterceptor(gateway);
      this.resInterceptorLocal = buildErrorResponseInterceptor(local);
    }

    componentWillUnmount() {
      gateway.interceptors.request.eject(this.reqInterceptor);
      gateway.interceptors.response.eject(this.resInterceptor);

      local.interceptors.request.eject(this.reqInterceptorLocal);
      local.interceptors.response.eject(this.resInterceptorLocal);
    }

    errorConfirmedHandler = () => {
      this.setState({ error: null });
    };

    render() {
      const { error } = this.state;
      return (
        <>
          <SweetAlertGeneric
            open={error !== null}
            message={(
              <p>
                Ocorreu um erro interno e não foi possível concluir sua solicitação.
                Tente novamente em alguns minutos e se o problema persistir,
                entre em contato com o Administrador.
              </p>
            )}
            closeDialog={this.errorConfirmedHandler}
          />
          <WrappedComponent {...this.props} />
        </>
      );
    }
  };
}
