import React, { createContext } from 'react';
import { applyMiddleware, createStore } from 'redux';
import { createDispatchHook, createSelectorHook, Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import reducer from './boleto.store';
import watchBoleto from './boleto.saga';

export const boletoContext = createContext();
export const useBoletoSelector = createSelectorHook(boletoContext);
export const useBoletoDispatch = createDispatchHook(boletoContext);

export default function BoletoProvider({ children }) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(watchBoleto);

  return (
    <Provider context={boletoContext} store={store}>
      {children}
    </Provider>
  );
}
