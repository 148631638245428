import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Boleto from '../components/Boleto/Boleto';
import BoletoProvider from '../store/boleto/BoletoProvider';

function RoutesSettings() {
  const boletoComponent = () => (
    <BoletoProvider>
      <Boleto />
    </BoletoProvider>
  );

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/pdf-boleto/:credentials" component={boletoComponent} />
      </Switch>
    </BrowserRouter>
  );
}

export default RoutesSettings;
