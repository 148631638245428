import { gateway } from '../api.service';

const { REACT_APP_API_ABE_WEB_URL, REACT_APP_API_DELLPHI_URL } = process.env;

export function buscarParametrosGerarBoleto(credentials) {
  return gateway.get(`${REACT_APP_API_DELLPHI_URL}/processos/acordos/boleto/buscar-parametros-gerar-boleto/${credentials}`);
}

export function imprimirBoletoAbeWeb(idAcordo, nroParcela) {
  return gateway.get(`${REACT_APP_API_ABE_WEB_URL}/processos/acordos/boleto/imprimir/${idAcordo}/${nroParcela}`);
}

export function imprimirBoletoDelphi(idProcesso, idBoleto) {
  return gateway.get(`${REACT_APP_API_DELLPHI_URL}/processos/acordos/boleto/imprimir/${idProcesso}/${idBoleto}`);
}
