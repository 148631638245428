import axios from 'axios';

const URL = process.env.REACT_APP_API_URL;
const URL_LOGIN = process.env.REACT_APP_LOGIN_URL;

export const gateway = axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 60000,
});

export const gatewayLogin = axios.create({
  baseURL: URL_LOGIN,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 60000,
});

export const local = axios.create({
  baseURL: 'http://localhost:8082',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 60000,
});
