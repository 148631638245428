import { call, put, takeLatest } from 'redux-saga/effects';
import {
  IMPRIMIR_BOLETO_ABE_WEB_ACTION, BUSCAR_PARAMETROS_GERAR_BOLETO_ACTION, IMPRIMIR_BOLETO_DELPHI_ACTION,
} from './boleto.constants';
import { openPagePDFBase64 } from '../../utils/utils';
import { buscarParametrosGerarBoleto, imprimirBoletoAbeWeb, imprimirBoletoDelphi } from '../../services/Boleto/boleto.service';
import { setParametrosDecriptografadosAction } from './boleto.store';
import { getAuthentitionInformation } from '../../services/Autenticacao/auth.service';


export const buscarParametrosGerarBoletoAction = credentials => ({
  type: BUSCAR_PARAMETROS_GERAR_BOLETO_ACTION,
  credentials,
});

export const imprimirBoletoAbeWebAction = (idAcordo, nroParcela) => ({
  type: IMPRIMIR_BOLETO_ABE_WEB_ACTION,
  idAcordo,
  nroParcela,
});

export const imprimirBoletoDelphiAction = (idProcesso, idBoleto) => ({
  type: IMPRIMIR_BOLETO_DELPHI_ACTION,
  idProcesso,
  idBoleto,
});

function* buscarParametrosGerarBoletoHandler(actions) {
  const { credentials } = actions;

  const response = yield call(buscarParametrosGerarBoleto, credentials);
  yield put(setParametrosDecriptografadosAction(response?.data));
}


function* imprimirBoletoDelphiHandler(actions) {
  const { idProcesso, idBoleto } = actions;

  const response = yield call(imprimirBoletoDelphi, idProcesso, idBoleto);
  if (response.data != null) {
    yield openPagePDFBase64(response.data);
  }
}

function getExpirationDate(expirationIn) {
  return new Date(new Date().getTime() + expirationIn * 1000);
}

function getPermissoes(response) {
  if (response.permissoes) {
    return response.permissoes;
  }
  return '';
}

function buildUsuario(usuario, accessToken, refreshToken) {
  if (!accessToken && !refreshToken) {
    return usuario;
  }
  return {
    ...usuario,
    accessToken,
    refreshToken,
    permissoes: getPermissoes(usuario),
    expirationDate: getExpirationDate(usuario.expirationIn),
  };
}

function* authenticateStep(response, accessToken, refreshToken) {
  const usuario = yield buildUsuario(response, accessToken, refreshToken);

  yield localStorage.setItem('userData', JSON.stringify(usuario));
  yield sessionStorage.setItem('nome', usuario.nomeUsuario);
}

export function* authenticate(username, password) {
  const response = yield getAuthentitionInformation(username, password);
  yield authenticateStep(response.data, response.headers['access-token'], response.headers['refresh-token']);
}

function* imprimirBoletoAbeWebHandler(actions) {
  const { idAcordo, nroParcela } = actions;

  yield authenticate('<Sistema>', 'Maria');
  const response = yield call(imprimirBoletoAbeWeb, idAcordo, nroParcela);
  if (response.data != null) {
    yield openPagePDFBase64(response.data);
  }
}

export default function* watchBoleto() {
  yield takeLatest(BUSCAR_PARAMETROS_GERAR_BOLETO_ACTION, buscarParametrosGerarBoletoHandler);
  yield takeLatest(IMPRIMIR_BOLETO_ABE_WEB_ACTION, imprimirBoletoAbeWebHandler);
  yield takeLatest(IMPRIMIR_BOLETO_DELPHI_ACTION, imprimirBoletoDelphiHandler);
}
