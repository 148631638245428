import { gatewayLogin } from '../api.service';

const { REACT_APP_LOGIN_URL } = process.env;

const LOGIN = '/autenticacao/login';

export const getAuthentitionInformation = (usernameParam, passwordParam) => (
  gatewayLogin.post(`${REACT_APP_LOGIN_URL}${LOGIN}`, {}, {
    headers: {
      usuario: usernameParam,
      senha: passwordParam,
    },
  })
);
