import { SET_PARAMETROS_DECRIPTOGRAFADOS_ACTION } from './boleto.constants';

function buildInitialStates() {
  return {
    parametrosDecriptografados: '',
  };
}

export const setParametrosDecriptografadosAction = parametrosDecriptografados => ({
  type: SET_PARAMETROS_DECRIPTOGRAFADOS_ACTION,
  parametrosDecriptografados,
});

function setParametrosDecriptografadosHandler(states, actions) {
  const { parametrosDecriptografados } = actions;
  return {
    ...states,
    parametrosDecriptografados,
  };
}

export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    case SET_PARAMETROS_DECRIPTOGRAFADOS_ACTION:
      return setParametrosDecriptografadosHandler(states, actions);
    default:
      return states;
  }
};
